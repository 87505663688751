<template>
  <div class="support-page text-center">
    <h1 class="mb-2">{{ $t("fancyChallange") }}</h1>
    <h1 class="mb-4">{{ $t("careerTaxText") }}</h1>
    <p class="mb-4">
      {{ $t("careerText") }}
    </p>
    <a href="mailto:mail@flintax.de">
      <button class="blue-button">{{ $t("careerButton") }}</button>
    </a>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.support-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: justify;

  .support-link {
    color: #57c6ff;
    text-decoration: underline !important;
  }
}
</style>